.siteLayoutContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  display: grid;
  position: relative;
  width: 100%;
}

.gridLargeWrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(2 * var(--grid-margins-lg) + var(--content-width-lg));
  padding-left: var(--grid-margins-xxs);
  padding-right: var(--grid-margins-xxs);
}

.gridSmallWrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(2 * var(--grid-margins-xs) + var(--content-width-sm));
  padding-left: var(--grid-margins-xxs);
  padding-right: var(--grid-margins-xxs);
}

.contentVerticalPadding {
  padding-top: var(--spacing-7);
  padding-bottom: var(--spacing-7);
}

.fullHeight {
  flex-grow: 1;
}

.appBottomPadding {
  padding-bottom: 120px;
}

@media (min-width: 480px) {
  .gridLargeWrapper {
    padding-left: var(--grid-margins-xs);
    padding-right: var(--grid-margins-xs);
  }

  .gridSmallWrapper {
    padding-left: var(--grid-margins-xs);
    padding-right: var(--grid-margins-xs);
  }
}

@media (min-width: 768px) {
  .gridLargeWrapper {
    padding-left: var(--grid-margins-sm);
    padding-right: var(--grid-margins-sm);
  }

  .contentVerticalPadding {
    padding-top: var(--spacing-9);
    padding-bottom: var(--spacing-9);
  }
}

@media (min-width: 992px) {
  .gridLargeWrapper {
    padding-left: var(--grid-margins-md);
    padding-right: var(--grid-margins-md);
  }
}

@media (min-width: 1148px) {
  .gridLargeWrapper {
    padding-left: var(--grid-margins-lg);
    padding-right: var(--grid-margins-lg);
  }
}
